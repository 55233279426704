import React from 'react'
import { Box,Link } from 'theme-ui'
import PoweredByGatsby from '@components/PoweredByGatsby'
import { HeaderLogo } from '../Header/Header.Logo'

const styles = {
  logo: {
    pb: 1,
    mb: 2,
    mt: [4, 0]
  },
  copyright: {
    pt: 2,
    mb: [2, 4]
  },
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `light`,
    ':visited': {
      color: `heading`
    },
    pt: 3
  }
}

export const FooterLogo = () => (
  <>
    <Box sx={styles.logo}>
      <HeaderLogo grayscale />
    </Box>
    <Box sx={styles.copyright}>
      © {new Date().getFullYear()}, All Rights Reserved.
    </Box>
    <Box>
      <PoweredByGatsby />
    </Box>
    <Box>
        <Link
        variant='mute'
        target='_blank'
        title='freepik'
        href='https://www.freepik.com/free-vector/group-k-pop-boys-band_9456421.htm'
        rel='noopener'
        sx={styles.link}
        >
        Vector created by pikisuperstar
        </Link>
    </Box>
  </>
)
